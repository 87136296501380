import { validateStatus } from '@/lib/status'
import { TRIP_STATUS_VALIDATOR, TRIP_STATUS, TRIP_ACTION } from '@/enums'
import { useUser, useCompanies, useTrips, useExport, useReceipts } from '@/composables'

export default function useReportButtons() {
  const { activeUser } = useUser()
  const { activeCompany } = useCompanies()
  const { activeTrip } = useTrips()
  const { canExport, canExportAndCopy } = useExport()
  const { activeTripReceipts } = useReceipts()

  const isButtonAccessible = (buttonAction: string) => {
    switch (buttonAction) {
      case TRIP_ACTION.ADD_DOCUMENT:
      case TRIP_ACTION.ADD_MILEAGE:
      case TRIP_ACTION.COPY_EMAIL:
        return !validateStatus(activeTrip.value?.status, [TRIP_STATUS.CONFIRMED])
      case TRIP_ACTION.EXPORT:
        return (
          canExport(activeCompany.value, activeUser.value) &&
          validateStatus(activeTrip.value?.status, [TRIP_STATUS.CONFIRMED]) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.APPROVING) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.PROCESSING)
        )
      case TRIP_ACTION.EXPORT_AND_COPY:
        return (
          canExportAndCopy(activeCompany.value, activeUser.value) &&
          validateStatus(activeTrip.value?.status, [TRIP_STATUS.CONFIRMED]) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.APPROVING) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.PROCESSING)
        )
      case TRIP_ACTION.UNARCHIVE:
        return (
          validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.SENT) ||
          validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.PROCESSING)
        )
      case TRIP_ACTION.EDIT:
        return (
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.CONFIRMED) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.SENT) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.APPROVING) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.APPROVED) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.PROCESSING)
        )
      case TRIP_ACTION.CONFIRM_AND_SEND:
        return (
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.SENT) &&
          activeCompany.value?.settings?.requireReportConfirmation
        )
      case TRIP_ACTION.SEND:
        return (
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.APPROVING) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.PROCESSING) &&
          !validateStatus(activeTrip.value?.status, TRIP_STATUS_VALIDATOR.SENT) &&
          !(
            activeTrip.value?.type === 'expense' &&
            activeTripReceipts.value?.length < 1 &&
            activeTrip.value?.subtype !== 'mileage'
          ) &&
          !activeCompany.value?.settings?.requireReportConfirmation
        )
    }
  }
  return {
    isButtonAccessible
  }
}
