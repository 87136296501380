import { INTEGRATION } from '@/enums'

export default function (integration, countryCode) {
  if (!integration?.id) {
    return ''
  }

  switch (integration.id) {
    case INTEGRATION.E_ARVELDAJA:
    case INTEGRATION.E_ARVELDAJA_NEW:
      return 'E-arveldaja'
    case INTEGRATION.EMAIL:
      return 'Excel / PDF'
    case INTEGRATION.EMCE_XML:
      return 'EmCe (XML)'
    case INTEGRATION.ERPLYBOOKS:
      return 'ERPLY Books'
    case INTEGRATION.MERIT:
      if (countryCode === 'PL') {
        return '360 Księgowość'
      }
      if (countryCode === 'FI') {
        return 'Passeli Merit'
      }
      return 'Merit Aktiva'
    case INTEGRATION.TELEMA:
      return 'Telema eFlow'
    case INTEGRATION.HANSAWORLD:
      return 'Standard ERP'
    case INTEGRATION.I_FIRMA:
      return 'iFirma'
    case 'omniva':
      return 'Omniva'
    case INTEGRATION.ONE_S:
      if (countryCode === 'EE') {
        return 'DataService 1C'
      }
      return 'FastSoft 1C'
    case INTEGRATION.RAPID:
      return 'Rapid'
    case INTEGRATION.MSBC:
      return 'Business Central'
    case INTEGRATION.JOOSEP_EMAIL:
      return 'Joosep (email)'
    case INTEGRATION.RAKS_SQL:
      return 'RAKS'
    case INTEGRATION.SAF:
      return 'SAF'
    case INTEGRATION.SIMPLBOOKS:
      return 'SimplBooks'
    case INTEGRATION.SMARTACCOUNTS:
      return 'SmartAccounts'
    case INTEGRATION.JUMIS_START:
      return 'Jumis Start'
    case INTEGRATION.JUMIS_PRO:
      return 'Jumis Pro'
    case INTEGRATION.VISMA_NETVISOR:
      return 'Visma Netvisor'
    case INTEGRATION.VISMA_FIVALDI:
      return 'Visma Fivaldi'
    case INTEGRATION.VISMA_FIVALDI_CSV:
      return 'Visma Fivaldi (CSV)'
    case INTEGRATION.VISMA_HORIZON:
      return 'Visma Horizon'
    case INTEGRATION.FITEK:
      if (integration.name === INTEGRATION.E_ARVELDAJA) {
        return 'E-arveldaja (vana)'
      } else if (integration.name) {
        return integration.name[0].toUpperCase() + integration.name.substring(1)
      }
    default:
      return integration.id[0].toUpperCase() + integration.id.substring(1)
  }
}
