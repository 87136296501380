import { computed } from 'vue'
import xstore from '@/store'
import { useContext } from '@/composables'
import { PaymentMethod, PaymentMethods } from '@/models'
import { PAYMENTS_STORE_ACTIONS, PAYMENTS_STORE_GETTERS } from '@/enums'

export default function usePaymentMethods() {
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId) || '')

  const paymentMethods = computed<PaymentMethods>(() => {
    return xstore.getters[PAYMENTS_STORE_GETTERS.PAYMENT_METHODS][companyId.value] || {}
  })

  const paymentMethodsForUser = (userId: number | null | undefined): PaymentMethod[] =>  {
    if (!userId) return []
    return Object.values(paymentMethods.value).filter((method: PaymentMethod) => {
      return !method.userId || method.userId == userId
    })
  }

  const paymentMethodById = (id: string | number): PaymentMethod => {
    return paymentMethods.value?.[id] || {}
  }

  const loadPaymentMethods = () => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.GET_PAYMENT_METHODS, {
      companyId: companyId.value
    })
  }

  const createPaymentMethod = (newPaymentMethod: PaymentMethod) => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.CREATE_PAYMENT_METHOD, {
      companyId: companyId.value,
      newPaymentMethod
    })
  }

  // TODO:
  // const updateExistingMethod = async (type) => {
  //   const updatedMethod = await Axios.put(`@coreapi/companies/${companyId}/payment-methods/${paymentMethodId}`, {
  //     type
  //   }).then(({ data = {} }) => data)
  //   const updated = [...root?.$stash.paymentMethods[companyId]].map((method) =>
  //     method.id === updatedMethod.id ? updatedMethod : method
  //   )
  //   root?.$set(root?.$stash.paymentMethods, companyId, updated)
  //   return
  // }

  return {
    paymentMethods,
    paymentMethodById,
    paymentMethodsForUser,
    loadPaymentMethods,
    createPaymentMethod
  }
}
