import UiNotification from '@ui/components/UiNotification'
import emitter from 'tiny-emitter/instance'

const Plugin = {
  install: function(app, options = {}) {
    if (this.installed) {
      return
    }

    this.installed = true

    app.config.globalProperties.$notification = (message, type, timeToDisappear, bottomOffset, options) => {
      emitter.emit('appear', message, type, timeToDisappear, bottomOffset, options)

      if (type === 'error') {
        console.log(message)
      }
    }

    app.component('ui-notification', UiNotification)

    app.mixin({
      beforeMount () {
        if (!Plugin.rootInstance) {
          Plugin.rootInstance = app
        }
      }
    })
  }
}

export default Plugin