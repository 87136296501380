import {
  ComponentInternalInstance,
  ComponentPublicInstance,
  getCurrentInstance,
  computed,
  inject
} from 'vue'

import {
  routerLocationKey
} from '@/providers'

import {
  RootInstance
} from '../types'

import { xstore } from '@/store'

export default function useContext () {
  const instance: ComponentInternalInstance | null = getCurrentInstance()
  const root: RootInstance | null = instance?.proxy ?? null
  const router = inject(routerLocationKey)
  const store = xstore

  const route = computed(() => {
    return router?.currentRoute.value
  })

  return {
    router,
    route,
    store,
    root
  }
}